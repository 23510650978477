import axios, { type Axios } from 'axios';

import registerAppStore from '../stores/registration/RegisterAppStore.tsx';

const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

const registrationApClient: Axios = axios.create({
  headers: {
    'X-CSRF-Token': token || '',
    Accept: 'application/json',
  },
});

registrationApClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // session token expired which is 12 hrs
      registerAppStore.setSessionExpired(true);
    } else if (error.response?.data?.insufficient_permission === true) {
      // Insufficient permission
      registerAppStore.setInsufficientPermission(true);
    } else if (error.response?.data?.record_insufficient_permission === true) {
      // Record Insufficient permission
      registerAppStore.setRecordInsufficientPermission(true);
    }
    return Promise.reject(error);
  }
);

export default registrationApClient;
