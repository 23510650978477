import { useEffect } from 'react';

import Flex from '../Flex/Flex.tsx';

import Banner, { Variant } from '../Banner/Banner.tsx';
import { Errors, extractPanelErrors } from '../../../utils/Errors.tsx';
import generateReactKey from '../../../utils/Uuid.tsx';

import { useAppStore } from '../../../providers/AppStoreProvider.tsx';
import { useRegisterAppStore } from '../../../providers/registration/RegisterAppStoreProvider.tsx';

type ErrorPanelProps = {
  errors: Errors;
  variant?: Variant;
  className?: string;
};
function ErrorPanel({ errors, className = '', variant = 'Error' }: ErrorPanelProps) {
  const appStore = useAppStore();
  const registerAppStore = useRegisterAppStore();
  const errorList = extractPanelErrors(errors);

  useEffect(() => {
    appStore.setToastDetails({
      id: generateReactKey(),
      title: 'Failed',
      content: String(errorList),
      variant: 'Error',
      isHidden: true,
    });

    registerAppStore.setToastDetails({
      id: generateReactKey(),
      title: 'Failed',
      content: String(errorList),
      variant: 'Error',
      isHidden: true,
    });
  }, [appStore, errorList, registerAppStore]);

  return (
    <div className={className}>
      <Flex flexDirection="column" rowGap="8px" width="100%">
        {errorList.map((error) => (
          <Banner key={generateReactKey()} variant={variant} dismissible={false}>
            {error}
          </Banner>
        ))}
      </Flex>
    </div>
  );
}

export default ErrorPanel;
