/* eslint-disable react-refresh/only-export-components */
import { createContext, ReactNode, useContext } from 'react';

import appStore from '../stores/AppStore.tsx';

type Props = {
  children: ReactNode;
};

const AppStoreContext = createContext<typeof appStore>(appStore);

function AppStoreProvider({ children }: Props) {
  return <AppStoreContext.Provider value={appStore}>{children}</AppStoreContext.Provider>;
}

function useAppStore(): typeof appStore {
  const appStoreContext = useContext(AppStoreContext);
  if (appStoreContext === undefined) {
    throw new Error('AppStoreContext must be used within a AppStoreProvider');
  }
  return appStoreContext;
}

export { AppStoreProvider, useAppStore };
