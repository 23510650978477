import { type ReactNode } from 'react';

type Props = {
  flexDirection?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  justifyContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'safe center'
    | 'unsafe center';
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'safe center'
    | 'unsafe center';
  alignItems?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'self-start'
    | 'self-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'safe center'
    | 'unsafe center';
  gap?: string;
  columnGap?: string;
  rowGap?: string;
  // Layout
  flex?: string;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  alignSelf?:
    | 'auto'
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | 'stretch';
  justifySelf?:
    | 'auto'
    | 'normal'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | 'center'
    | 'left'
    | 'right'
    | 'stretch';
  order?: number;
  // Spacing
  margin?: string;
  padding?: string;
  // Sizing
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  // Positioning
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  zIndex?: number;
  // Custom Classes
  className?: string;
  // Children
  children: ReactNode;
  dataTestId?: string | null;
  role?: string;
};

function Flex({
  flexDirection = 'row',
  flexWrap,
  justifyContent,
  alignContent,
  alignItems,
  gap,
  columnGap,
  rowGap,
  flex,
  flexGrow,
  flexShrink,
  flexBasis,
  alignSelf,
  justifySelf,
  order,
  margin,
  padding,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  position,
  top,
  bottom,
  left,
  right,
  zIndex,
  className = '',
  children,
  dataTestId = null,
  role = '',
}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection,
        flexWrap,
        justifyContent,
        alignContent,
        alignItems,
        gap,
        columnGap,
        rowGap,
        flex,
        flexGrow,
        flexShrink,
        flexBasis,
        alignSelf,
        justifySelf,
        order,
        margin,
        padding,
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        position,
        top,
        bottom,
        left,
        right,
        zIndex,
      }}
      className={className}
      data-testid={dataTestId}
      role={role}
    >
      {children}
    </div>
  );
}

export default Flex;
