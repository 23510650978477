import { ReactNode } from 'react';

import Dismiss from '../../../assets/cancel.svg?react';
import InfoIcon from '../../../assets/info-badge.svg?react';
import WarningIcon from '../../../assets/warning-badge.svg?react';
import ErrorIcon from '../../../assets/error-badge.svg?react';

import styles from './Banner.module.css';

export type Variant = 'Success' | 'Info' | 'Warning' | 'Error' | 'Alert';

type BannerProps = {
  variant: Variant;
  dismissible?: boolean;
  leftIcon?: ReactNode;
  onDismissClick?: () => void;
  children?: ReactNode;
};

function Banner({ variant = 'Info', dismissible = true, leftIcon, onDismissClick, children }: BannerProps) {
  let startIcon: ReactNode;

  if (variant === 'Info') {
    startIcon = <InfoIcon />;
  } else if (variant === 'Warning') {
    startIcon = <WarningIcon />;
  } else {
    startIcon = <ErrorIcon />;
  }

  if (leftIcon) {
    startIcon = leftIcon;
  }

  return (
    <div className={`${styles.Banner} ${styles[`BannerBackground${variant}`]}`}>
      <span className={`${styles.BannerLeftIcon}`}>{startIcon}</span>

      <div className={styles.BannerContent}>
        <span className={`${styles.BannerText} ${styles[`BannerTextColor${variant}`]}`}>{children}</span>
      </div>

      {dismissible && (
        <Dismiss
          className={`${styles.BannerDismissIcon} ${styles[`BannerDismissIconTint${variant}`]}`}
          role="button"
          aria-label="Dismiss"
          onClick={onDismissClick}
        />
      )}
    </div>
  );
}

export default Banner;
