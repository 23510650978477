import { format, parse, lastDayOfMonth, isAfter, isBefore } from 'date-fns';

import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

import moment from 'moment-timezone';

import { Moment } from 'moment';

import { SelectItemData } from '../components/common/Select/Select.tsx';

import { Timezone } from '../models/OfficeHoursModel.ts';

import TimezoneList from './constants/Timezones.json';

export type Timezones = Array<{
  gmt: string;
  iana: string;
}>;

export const modifyTimezone = (timezones: Timezones) =>
  timezones.map((timezone) => ({ text: timezone.gmt, value: timezone.iana }));

export const formatSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (hours > 24) {
    return {
      label: `${hours} hours ${formattedMinutes} minutes & ${formattedSeconds} seconds`,
      value: `${hours}h ${formattedMinutes}m ${formattedSeconds}s`,
    };
  }
  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, '0');
    return {
      label: `${formattedHours} hours ${formattedMinutes} minutes & ${formattedSeconds} seconds`,
      value: `${formattedHours}:${formattedMinutes}:${formattedSeconds}`,
    };
  }
  return {
    label: `${formattedMinutes} minutes & ${formattedSeconds} seconds`,
    value: `${formattedMinutes}:${formattedSeconds}`,
  };
};

export const momentToDate = (momentDate: Moment): Date => {
  return new Date(momentDate.year(), momentDate.month(), momentDate.date(), momentDate.hour(), momentDate.minute());
};

export const newDate = (timezone: string): Date => toZonedTime(new Date(), timezone);

export const getToday = (dateFormat: string, timezone: string) => formatInTimeZone(new Date(), timezone, dateFormat);

export const getTodayDayInCapitalCase = (timezone: string) => getToday('eeee', timezone).toUpperCase();

export const getTodayDayOfMonth = (timezone: string) => getToday('d', timezone);

export const getTodayMonth = (timezone: string) => getToday('MMMM', timezone).toUpperCase();

// If today is Sunday, it will return which number of Sunday is it in this month, - 1,2,3,4,5
export const getTodayWeekNumber = (timezone: string) => {
  const todayMoment = moment().tz(timezone);
  const todayMonth = todayMoment.month();
  let weekdayInMonth = 0;
  while (todayMoment.month() === todayMonth && weekdayInMonth < 5) {
    todayMoment.subtract(1, 'week');
    weekdayInMonth += 1;
  }
  return weekdayInMonth;
};

export const getTimeRoundedToNext5Minutes = (timezone: string): Moment => {
  const roundedTime = moment().tz(timezone);
  const minutes = roundedTime.minutes();
  const roundedMinutes = Math.ceil((minutes % 5 === 0 ? minutes + 1 : minutes) / 5) * 5;
  roundedTime.minutes(roundedMinutes);

  return roundedTime;
};

export const daysOfMonthFor = (month: string, timezone: string): Array<SelectItemData> => {
  const date = parse(month, 'MMMM', newDate(timezone));

  const lastDay: number = lastDayOfMonth(date).getDate();

  return Array<string>(lastDay)
    .fill('')
    .map((v, index) => `${index + 1}${v}`)
    .map((v) => {
      return {
        text: v,
        value: v,
        disabled: false,
      };
    });
};

export const localTime = (time: string | Date | Moment, timezone: string) => moment(time).tz(timezone);

export const ianaToGmt = (ianaCode: string, timezones: Timezones): string | null => {
  const timeZone = timezones.find((tz) => tz.iana === ianaCode);

  if (timeZone) {
    return timeZone.gmt;
  }
  return null;
};

export const ianaToTimezone = (ianaCode: string, timezones: Timezones): string | null => {
  const timeZone = timezones.find((tz) => tz.iana === ianaCode);

  if (timeZone) {
    if (timeZone.gmt.includes(')')) {
      return timeZone.gmt.slice(timeZone.gmt.indexOf(')') + 1).trim();
    }
    return timeZone.gmt;
  }
  return null;
};

export const formatLocalTime = (time: string | Date | Moment, timezone: string, fmt: string) =>
  moment(time).tz(timezone).format(fmt);

export const dateFromString = (dateString: string | null, dateFormat: string = 'yyyy-MM-dd'): Date | null => {
  if (dateString) {
    const parsedDate = parse(dateString, dateFormat, new Date());
    parsedDate.setHours(0, 0, 0, 0);
    return parsedDate;
  }
  return null;
};

export const dateToString = (date: Date | null, dateFormat: string = 'yyyy-MM-dd'): string | null => {
  if (date) {
    return format(date, dateFormat);
  }
  return null;
};

export const isDateRangeValid = (startDate: Date | null, endDate: Date | null) => {
  if (startDate && endDate) {
    if (isAfter(startDate, endDate) || isBefore(endDate, startDate)) {
      return false;
    }
    return true;
  }
  return false;
};

export const getTimezoneOffset = (timezoneName: string) => `GMT${moment().tz(timezoneName).format('Z')}`;

export const getTimezoneList = async (): Promise<Timezones> => {
  return new Promise((resolve, reject) => {
    try {
      const result = TimezoneList.data
        .map((timezone: { city: string; iana: string }) => {
          const momentDate = moment().tz(timezone.iana);
          if (timezone.city === 'Coordinated Universal Time') {
            return { gmt: `(UTC) ${timezone.city}`, iana: `${timezone.iana}` };
          }
          return { gmt: `(UTC${momentDate.format('Z')}) ${timezone.city}`, iana: `${timezone.iana}` };
        })
        .sort((timezone1: Timezone, timezone2: Timezone) => {
          const offset1 = moment().tz(timezone1.iana).utcOffset();
          const offset2 = moment().tz(timezone2.iana).utcOffset();
          return offset1 - offset2 || timezone1.gmt.localeCompare(timezone2.gmt);
        });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// todo add more code for time manipulation related here
