export type SupportedFeature = 'spark' | 'webex' | 'office_hours' | 'setup' | 'personal_recordings';
export type SupportedAdminFeature = 'admin_webex' | 'admin_spark' | 'admin_analytics' | 'admin_settings';
export type WebexLoginType = 'oauth' | 'password' | 'undetected';

export type AssignmentGroup = {
  key: string;
  value: string;
};

export type LMSData = {
  platform: string;
  authorized: boolean;
  assignment_groups: Array<AssignmentGroup>;
  has_support_for_assignment_groups: boolean;
};

export type SparkTeam = {
  web_link: string;
  protocol_handler: string;
};

export type SparkData = {
  access_token?: string;
  is_team_creator: boolean;
  current_team: SparkTeam;
  authenticated: boolean;
  all_permissions: boolean;
  is_any_team_moderator: boolean;
};

export type WebexData = {
  username?: string;
  need_webex_reauthorization: boolean;
};

export type AdminFeature = {
  classroom_collaboration: boolean;
  webex_enabled: boolean;
  personal_recordings_enabled: boolean;
  office_hours_enabled: boolean;
  non_instructor_webex_enabled: boolean;
};

export type MeetingRecording = {
  id: number;
  course_name: string;
  creator: string;
  duration: string;
  name: string;
  start_date: string;
};

export type CourseUsage = {
  course_name: string;
  count: number;
};

export type CourseInfo = {
  total_count: number;
  values: Array<CourseUsage>;
};

export type CourseAnalytics = {
  courses_using_teams: CourseInfo;
  courses_using_meetings: CourseInfo;
  courses_using_office_hours: CourseInfo;
};

export type AdminAnalytics = {
  spark_spaces_count: number;
  instructor_meetings_count: number;
  students_meetings_count: number;
  office_seconds_booked: number;
  analytics: CourseAnalytics;
};

export type RosterFreq = 'daily' | 'weekly';

export type RosterConfigData = {
  automatic_sync_enabled: boolean;
  schedule_period: RosterFreq;
};

export type AppData = {
  id?: string;
  is_instructor: boolean;
  is_admin: boolean;
  full_name: string;
  course_name: string;
  roles: string;
  timezone: string;
  enabled_features: Array<SupportedFeature>;
  lms: LMSData;
  spark: SparkData;
  webex: WebexData;
  authorized: boolean;
  read_only: boolean;
  admin_feature: AdminFeature;
  classroom_collaboration: boolean;
  lms_local_user_id?: string;
  webex_support_url?: string;
  user_roles: Array<string>;
  disabled_roles: Array<string>;
  session_ticket?: string;
  recaptcha_site_key: string;
  recaptcha_checkbox_site_key: string;
  admin_params: AdminFeature;
  roster_sync_config: RosterConfigData;
  lti_1p3_enabled: boolean;
  registration_id: number | null;
  registration_number: string;
  user_uuid: string;
  course_created_at: string;
  user_created_at: string;
};

class AppDataLoader {
  _appData: AppData;

  constructor() {
    const rootElement = document.querySelector('#react-root');
    const rawAppData = rootElement ? rootElement.getAttribute('data-app-data') : '';
    this._appData = rawAppData ? JSON.parse(rawAppData) : {};
  }

  get noCookieToken(): string | undefined {
    return this._appData.session_ticket;
  }

  get captchaSiteKey(): string {
    return this._appData.recaptcha_site_key;
  }

  get captchaCheckboxSiteKey(): string {
    return this._appData.recaptcha_checkbox_site_key;
  }

  get appData(): AppData {
    return this._appData;
  }

  get lmsData(): LMSData {
    return this._appData.lms;
  }

  get sparkData(): SparkData {
    return this._appData.spark;
  }

  get webexData(): WebexData {
    return this._appData.webex;
  }

  get adminFeature(): AdminFeature {
    return this._appData.admin_params;
  }
}

const appDataLoader = new AppDataLoader();
export default appDataLoader;
