import axios from 'axios';

export type ErrorsHash = { [key: string]: Array<string> };
export type ErrorsList = Array<string>;
export type Errors = ErrorsHash | ErrorsList | string;

export const FeatureUnavailableError = 'feature-unavailable';

const isPlainObject = (obj: Errors): boolean => {
  return typeof obj === 'object' && obj.constructor === Object;
};

const getValue = (errors: Errors, path: string, defaultValue?: Array<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return path.split('.').reduce((a: any, c: string) => (a && a[c] ? a[c] : defaultValue || null), errors);
};

export const extractPanelErrors = (errors: Errors, parseAllErrors: boolean = false): ErrorsList => {
  if (isPlainObject(errors)) {
    if (parseAllErrors) {
      return ([] as Array<string>).concat(...Object.values(errors)).filter((message) => typeof message === 'string');
    }
    return Object.keys(errors)
      .map((key) => getValue(errors, key))
      .filter(Boolean);
  }
  if (Array.isArray(errors)) {
    return errors;
  }
  if (typeof errors === 'string') {
    return [errors];
  }
  return [];
};

export const parseApiErrorResponse = (error: unknown): Errors => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.data?.need_spark_reauthorization === true || error.response.status === 401) {
        return '';
      }
      const errors = error.response.data?.errors;
      if (errors) {
        return errors;
      }
    } else if (error.request) {
      // Network error
      return "We're having trouble detecting your network. Check your network connection and try again.";
    }
  }
  // For all Others Error send generic error message
  return "Something's not quite right. We need to fix something on our end. Try again later.";
};
