/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { makeAutoObservable, action } from 'mobx';

// import AdminApi from 'src/api/adminApi';
// import SetupApi from 'src/api/setupApi';

import { Timezones, getTimezoneList } from '../utils/Time.tsx';

import { findUniqueValues, intersectArrays, isStringEmpty } from '../utils/StringsUtils.tsx';

import { Errors, parseApiErrorResponse } from '../utils/Errors.tsx';

import apiClient from '../apis/ApiClient.tsx';

import { ToastDetails } from '../components/common/Toast/Toast.tsx';

import appDataLoader, {
  type SupportedFeature,
  type WebexLoginType,
  type AssignmentGroup,
  type AdminFeature,
  type RosterConfigData,
  type SparkTeam,
} from './AppDataLoader.tsx';

export type TabLink = {
  title: string;
  path: string;
  value: string;
  url: string;
};

const TAB_LINKS: { [key in SupportedFeature]: TabLink } = {
  spark: {
    // todo need to confirm for classroom functionality
    value: 'spark',
    title: 'Classroom Collaboration',
    path: '/spark',
    url: '',
  },
  webex: {
    value: 'webex',
    title: 'Meetings',
    path: '/webex',
    url: '',
  },
  office_hours: {
    value: 'office_hours',
    title: 'Office Hours',
    path: '/office_hours',
    url: '',
  },
  setup: {
    value: 'setup',
    title: 'Setup',
    path: '/setup',
    url: '',
  },
  personal_recordings: {
    value: 'recording',
    title: 'Other Recordings',
    path: '/recording',
    url: '',
  },
};

const allFeatures: Array<SupportedFeature> = ['webex', 'personal_recordings', 'office_hours', 'spark', 'setup'];

let timeOutId: NodeJS.Timeout | null = null;

export const getTabLink = (tab: SupportedFeature): TabLink => TAB_LINKS[tab];

class AppStore {
  SECONDS_PER_MINUTE = 60;
  isInstructor: boolean = false;
  isAdmin: boolean = false;
  fullName: string = '';
  lmsUsername: string | undefined;
  courseName: string = '';
  timezone: string = '';

  enabledFeatures: Array<SupportedFeature> = [];
  assignmentGroups: Array<AssignmentGroup> = [];
  hasSupportForAssignmentGroups: boolean = false;
  canReadOnly: boolean = false;
  webexUsername: string | undefined;
  webexLoginType: WebexLoginType = 'oauth';
  needWebexReauthorization: boolean = false;
  lmsPlatform: string = '';
  lmsAuthorized: boolean = false;
  classroomCollaboration: boolean = false;
  adminFeature: AdminFeature;
  currentUserId: string | undefined;

  webexSupportUrl: string | undefined;
  noCookieToken: string | undefined;
  captchaSiteKey: string;
  captchaCheckboxSiteKey: string;
  fetchingAdminParams: boolean;
  serverError: Errors = '';
  userRoles: Array<string> = [];
  disabledRoles: Array<string> = [];

  timezones: Timezones = [];
  syncRosterConfig: RosterConfigData | null = null;
  sparkTeam: SparkTeam | null = null;
  sparkAuthenticated: boolean = false;
  allPermissions: boolean = false;
  isAnyTeamModerator: boolean = false;
  registrationId: number | null = null;
  registrationNumber: string = '';
  lti3Enabled: boolean = false;
  cookiesEnabled: boolean = true;

  isLoggingOut: boolean;
  showAppLogoutModal: boolean;
  showWebexTokenExpiredModal: boolean;
  toastDetails: Array<ToastDetails> | [] = [];
  isAppTokenExpired: boolean;
  isAppMissingRegistration: boolean;
  appMissingRegError: string;

  userUuid: string = '';
  courseCreatedAt: string = '';
  userCreatedAt: string = '';

  constructor() {
    makeAutoObservable(this);
    const {
      appData,
      webexData,
      lmsData,
      noCookieToken,
      captchaSiteKey,
      captchaCheckboxSiteKey,
      adminFeature,
      sparkData,
    } = appDataLoader;
    this.adminFeature = adminFeature;

    if (appData) {
      this.isInstructor = appData.is_instructor;
      this.isAdmin = appData.is_admin;
      this.fullName = appData.full_name;
      this.lmsUsername = appData.lms_local_user_id;
      this.courseName = appData.course_name;
      this.timezone = appData.timezone || 'Etc/UTC';
      if (this.adminFeature) {
        this.enabledFeatures = this.createEnabledFeaturesList(appData.enabled_features);
      }
      this.canReadOnly = appData.read_only;
      this.webexSupportUrl = appData.webex_support_url;
      this.currentUserId = appData.id;
      this.userRoles = appData.user_roles;
      this.disabledRoles = appData.disabled_roles;
      this.syncRosterConfig = appData.roster_sync_config;
      this.registrationId = appData.registration_id;
      this.registrationNumber = appData.registration_number;
      this.lti3Enabled = appData.lti_1p3_enabled;
      this.userUuid = appData.user_uuid;
      this.courseCreatedAt = appData.course_created_at;
      this.userCreatedAt = appData.user_created_at;
    }

    if (adminFeature) {
      this.classroomCollaboration = adminFeature.classroom_collaboration;
    }

    if (lmsData) {
      this.assignmentGroups = lmsData.assignment_groups;
      this.hasSupportForAssignmentGroups = lmsData.has_support_for_assignment_groups;
      this.lmsPlatform = lmsData.platform;
      this.lmsAuthorized = lmsData.authorized;
    }

    if (webexData) {
      this.webexUsername = webexData.username;
      this.needWebexReauthorization = webexData.need_webex_reauthorization;
    }

    if (sparkData) {
      this.sparkTeam = sparkData.current_team;
      this.sparkAuthenticated = sparkData.authenticated;
      this.allPermissions = sparkData.all_permissions;
      this.isAnyTeamModerator = sparkData.is_any_team_moderator;
    }

    this.noCookieToken = noCookieToken;
    this.captchaSiteKey = captchaSiteKey;
    this.captchaCheckboxSiteKey = captchaCheckboxSiteKey;

    this.fetchingAdminParams = false;
    this.isLoggingOut = false;
    this.showAppLogoutModal = false;
    this.showWebexTokenExpiredModal = false;
    this.isAppTokenExpired = false;
    this.isAppMissingRegistration = false;
    this.appMissingRegError = '';
  }

  isEnabled(feature: string): boolean | Array<number> {
    return this.adminFeature[feature as keyof AdminFeature];
  }

  setEnabledFeatures(enabledFeatures: Array<SupportedFeature>) {
    this.enabledFeatures = this.createEnabledFeaturesList(enabledFeatures);
  }

  setAssignmentGroups(assignmentGroups: Array<AssignmentGroup>) {
    this.assignmentGroups = assignmentGroups;
  }

  get visibleTabs(): Array<SupportedFeature> {
    const showClassroomCollaboration = findUniqueValues(this.userRoles, this.disabledRoles);
    let features = intersectArrays(allFeatures, this.enabledFeatures);

    if (this.isInstructor) {
      features = features.concat(['setup']);
    }

    if (showClassroomCollaboration.length === 0) {
      features = features.filter((enFeatures) => enFeatures !== 'spark');
    }

    if (this.sparkTeam === null) {
      features = features.filter((enFeatures) => enFeatures !== 'spark');
    }

    return features as SupportedFeature[];
  }

  get isWebexAuthenticated(): boolean {
    return !isStringEmpty(this.webexUsername);
  }

  get supportLinkUrl(): string {
    return this.webexSupportUrl || `https://www.cisco.com/go/webex-${this.isInstructor ? 'teacher' : 'student'}`;
  }

  setWebexUsername(webexUsername: string | undefined) {
    this.webexUsername = webexUsername;
  }

  setWebexLoginType(webexLoginType: WebexLoginType) {
    this.webexLoginType = webexLoginType;
  }

  setLMSAuthorized(lmsAuthorized: boolean) {
    this.lmsAuthorized = lmsAuthorized;
  }

  setWebexSupportUrl(url: string) {
    this.webexSupportUrl = url;
  }

  setTimezone(timezone: string) {
    this.timezone = timezone;
  }

  setTimezones(timezones: Timezones) {
    this.timezones = timezones;
  }

  setNeedWebexReauthorization(value: boolean) {
    this.needWebexReauthorization = value;
  }

  setServerError(error: Errors) {
    this.serverError = error;
  }

  setIsLoggingOut(value: boolean) {
    this.isLoggingOut = value;
  }

  setShowLogoutModal(value: boolean) {
    this.showAppLogoutModal = value;
  }

  setClassroomCollaboration(value: boolean) {
    this.classroomCollaboration = value;
  }

  setAdminFeature(adminFeature: AdminFeature) {
    this.adminFeature = adminFeature;
  }

  setFetchingAdminParams(value: boolean) {
    this.fetchingAdminParams = value;
  }

  setRosterConfigdata(syncData: RosterConfigData | null) {
    this.syncRosterConfig = syncData;
  }

  setSparkteam(team: SparkTeam) {
    this.sparkTeam = team;
  }

  setSparkAuthenticated(value: boolean) {
    this.sparkAuthenticated = value;
  }

  setAllPermissions(value: boolean) {
    this.allPermissions = value;
  }

  setWebexTokenExpiredModal(value: boolean) {
    this.showWebexTokenExpiredModal = value;
  }

  setAppTokenExpired(value: boolean) {
    this.isAppTokenExpired = value;
  }

  setAppMissingRegistration(value: boolean) {
    this.isAppMissingRegistration = value;
  }

  setAppMissingRegError(value: string) {
    this.appMissingRegError = value;
  }

  setDisabledRoles(disableRoles: Array<string>) {
    this.disabledRoles = disableRoles;
  }

  clearToast() {
    timeOutId = setTimeout(() => {
      this.toastDetails.shift();
      if (this.toastDetails.length) {
        this.clearToast();
      } else if (timeOutId) {
        clearTimeout(timeOutId);
        timeOutId = null;
      }
    }, 5000);
  }

  setToastDetails(details: ToastDetails) {
    this.toastDetails = [...this.toastDetails, details];
    if (!timeOutId) {
      this.clearToast();
    }
  }

  removeToast(toastId: string) {
    const index = this.toastDetails.findIndex((toast) => toast.id === toastId);
    if (index >= 0 && index < this.toastDetails.length) {
      this.toastDetails.splice(index, 1);
    }
  }

  get syncRosterConfigData(): RosterConfigData {
    if (this.syncRosterConfig && Object.keys(this.syncRosterConfig).length !== 0) {
      return this.syncRosterConfig;
    }
    return { automatic_sync_enabled: true, schedule_period: 'daily' };
  }

  get isClassRoomEnabled(): boolean {
    return this.enabledFeatures.includes('spark') && this.sparkTeam !== null;
  }

  get isReEnablingClassRoom(): boolean {
    return !this.enabledFeatures.includes('spark') && this.sparkTeam !== null;
  }

  get isLMSAuthNeeded(): boolean {
    return !this.lti3Enabled && !this.lmsAuthorized;
  }

  resetRosterData() {
    this.setRosterConfigdata(null);
  }

  createEnabledFeaturesList(instructorEnabledFeatures: Array<SupportedFeature> = []): Array<SupportedFeature> {
    const finalEnabledFeatures = [];
    const { classroom_collaboration, office_hours_enabled, webex_enabled, personal_recordings_enabled } =
      this.adminFeature;

    if (classroom_collaboration && instructorEnabledFeatures.indexOf('spark') !== -1) {
      finalEnabledFeatures.push('spark');
    }
    if (webex_enabled && instructorEnabledFeatures.indexOf('webex') !== -1) {
      finalEnabledFeatures.push('webex');
    }
    if (office_hours_enabled && instructorEnabledFeatures.indexOf('office_hours') !== -1) {
      finalEnabledFeatures.push('office_hours');
    }
    if (personal_recordings_enabled && instructorEnabledFeatures.indexOf('personal_recordings') !== -1) {
      finalEnabledFeatures.push('personal_recordings');
    }
    return finalEnabledFeatures as SupportedFeature[];
  }

  get isSingleClassroomTab(): boolean {
    return !this.isInstructor && this.visibleTabs && this.visibleTabs.length === 1 && this.visibleTabs[0] === 'spark';
  }

  get isClassroomTabExternalLink(): boolean {
    return (
      this.isInstructor ||
      (this.visibleTabs && this.visibleTabs.length > 1) ||
      (this.visibleTabs && this.visibleTabs.length === 1 && this.visibleTabs[0] !== 'spark')
    );
  }

  async getAdminParams() {
    this.setFetchingAdminParams(true);

    try {
      const response = await apiClient.get('/api/admin/setup/all_admin_params');
      const adminParams = response.data.admin_app_data;
      this.setClassroomCollaboration(adminParams.classroom_collaboration);
      this.setAdminFeature(adminParams);
    } catch (error: any) {
      console.error('Error fetching data');
    }

    try {
      const response = await apiClient.get('/api/setup/features');
      this.setEnabledFeatures(this.createEnabledFeaturesList(response.data.enabled_features));
    } catch (error: any) {
      console.error('Error fetching data');
    }
    this.setFetchingAdminParams(false);
  }

  async getTimezones() {
    try {
      const response = await getTimezoneList();
      this.setTimezones(response);
    } catch (error: any) {
      console.error('Error fetching data');
    }
  }

  async logout() {
    this.isLoggingOut = true;
    this.serverError = '';

    try {
      await apiClient.delete('/spark/sessions/logout');
      this.sparkAuthenticated = false;
    } catch (error: any) {
      this.serverError = parseApiErrorResponse(error);
    } finally {
      this.isLoggingOut = false;
    }
  }

  setCookiesEnabled = action((state: boolean) => {
    this.cookiesEnabled = state;
  });

  showNavigation(path: string): boolean {
    // add more paths here where we dont need to show top nav
    return !['webex/create', 'webex/edit', '/error', '/reg_missing_error'].some((p) => path.includes(p));
  }
}

const appStore = new AppStore();
export default appStore;
