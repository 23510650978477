import { sanitizeUrl } from '@braintree/sanitize-url';

export const sanitizedSearchString = (searchString: string): string => searchString.trim().toLowerCase();

export const getRedirectPathFromURL = (urlString: string): string => urlString.split('/')[1];

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const isStringEmpty = (text?: string | null): boolean => !text || String(text).trim().length === 0;

export const isStringArrayEmpty = (array?: Array<string> | null): boolean =>
  !array || array.length === 0 || array.every((item) => isStringEmpty(item));

export const intersectArrays = (array1: Array<string>, array2: Array<string>) => {
  return array1.filter((value) => array2.includes(value));
};

export const findUniqueValues = (array1: Array<string>, array2: Array<string>) => {
  return array1.filter((value) => !array2.includes(value));
};

export const sort = (array: string[]) => {
  return array.sort((a, b) => (a > b ? 1 : -1));
};

export const isUrl = (url: string) => {
  const validUrlRegex =
    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/;

  return validUrlRegex.test(url);
};

export const isHttpsUrl = (url: string) => {
  const validUrlRegex =
    /^(https:\/\/)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/i;
  return validUrlRegex.test(url);
};

export const customSanitizedUrl = (url: string) => {
  const sanitizedValue: string = sanitizeUrl(url);
  return sanitizedValue.endsWith('/') ? sanitizedValue.slice(0, -1) : sanitizedValue;
};
