/* eslint-disable react-refresh/only-export-components */
import { createContext, ReactNode, useContext } from 'react';

import registerAppStore from '../../stores/registration/RegisterAppStore.tsx';

type Props = {
  children: ReactNode;
};

const RegisterAppStoreContext = createContext<typeof registerAppStore>(registerAppStore);

function RegisterAppStoreProvider({ children }: Props) {
  return <RegisterAppStoreContext.Provider value={registerAppStore}>{children}</RegisterAppStoreContext.Provider>;
}

function useRegisterAppStore(): typeof registerAppStore {
  const registerAppStoreContext = useContext(RegisterAppStoreContext);
  if (registerAppStoreContext === undefined) {
    throw new Error('AppStoreContext must be used within a AppStoreProvider');
  }
  return registerAppStoreContext;
}

export { RegisterAppStoreProvider, useRegisterAppStore };
